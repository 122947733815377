//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Detail",
  data() {
    return {
      currentYear: "",
      currentMonth: "",
      detailList: [],
    };
  },
  created() {
    this.currentYear = this.$route.query.year;
    this.currentMonth = this.$route.query.month;
    this.getDetail();
    this.$dd.ready(() => {
      this.$dd.biz.navigation.setTitle({
        title: "月报工明细",
      });
    });
  },
  activated() {
    this.$dd.ready(() => {
      this.$dd.biz.navigation.setTitle({
        title: "月报工明细",
      });
    });
  },
  mounted() {
    this.$dd.ready(() => {
      this.$dd.biz.navigation.setTitle({
        title: "月报工明细",
      });
    });
  },
  methods: {
    getDetail() {
      this.getData();
    },
    getData() {
      this.axios
        .post("/mes/jobbook/daysreport", {
          queryMap: {
            sdate: this.$moment(
              this.currentYear + "-" + this.currentMonth
            ).format("yyyyMMDD"),
            edate: this.$moment(this.currentYear + "-" + this.currentMonth)
              .endOf("month")
              .format("yyyyMMDD"),
          },
        })
        .then((res) => {
          if (res && res.data && res.data.code && res.data.code === "SUCCESS") {
            const temp = res.data.data;
            const dates = [];
            temp.forEach((item) => {
              item.dategroup =
                item.dategroup.slice(0, 4) +
                "-" +
                item.dategroup.slice(4, 6) +
                "-" +
                item.dategroup.slice(6, 8);
              dates.push(item.dategroup);
            });
            let dateSet = new Set(dates);
            let singleDate = Array.from(dateSet);
            let final = [];
            singleDate.forEach((date) => {
              let group = {
                date: date,
                items: [],
              };

              temp.forEach((item) => {
                if (item.dategroup === date) {
                  group.items.push(item);
                }
              });
              console.log(group);
              group.items.sort(function(a, b){
                return b.businessType.localeCompare(a.businessType);
              });

              final.push(group);
            });
            this.detailList = final;
          } else {
            this.$toast.fail(res.data.msg);
          }
        });
    },
  },
};
